import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

export class Error {
  key: string;
  message: string;
  source: any;

  constructor(key: string, message: string, source?: any) {
    this.key = key;
    this.message = message;
    this.source = source;
  }
}

export const SET_ERROR = 'SET_ERROR';

export function errorReducer(state: Error = null, action: Action & { payload?: any }): Error {
  switch (action.type) {
    case SET_ERROR:
      return action.payload;
    default:
      return state;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandler {
  lastError: Error;
  errorDisplayPromise: any;

  constructor(
    private translate: TranslateService,
    private store: Store<any>,
    private router: Router,
    private location: Location,
    snackBar: MatSnackBar) {

    this.error.subscribe((newError: Error) => {
      if (newError) {
        clearTimeout(this.errorDisplayPromise);

        if (this.lastError && this.lastError.key === newError.key) {
          const multipleKey = newError.key + '_multiple';
          const multipleMessage = translate.instant(newError.key + '_multiple');
          if (multipleKey !== multipleMessage) {
            newError.message = translate.instant(newError.key + '_multiple');
          }
        }

        this.lastError = newError;

        this.errorDisplayPromise = setTimeout(() => {
          snackBar.open(this.lastError.message, translate.instant('DISMISS'), {
            duration: 7000,
          });
          this.lastError = null;
        }, 500);
      }
    });
  }

  get error() {
    return this.store.select('error');
  }

  async handle(key: string, e?: any, params?: any, loginOnForbidden?: boolean): Promise<any> {
    let message = this.translate.instant(key, params);
    if (e && e.status === 403 && key !== 'Error.AccountFraud') {
      if (loginOnForbidden) {
        await this.router.navigate(['/backoffice/login', {redirectTo: this.location.path(true)}]);
      } else {
        message += ' ' + this.translate.instant('Error.Forbidden');
      }
    }

    if (e && e.status === 401 && key !== 'Error.LoggingIn') {
      message += ' ' + this.translate.instant('Error.Unauthorized');
    }

    this.store.dispatch({
      type: SET_ERROR,
      payload: {...new Error(key, message)}
    });

    console.error(message, e);
    return Promise.reject(key);
  }
}
