import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from './auth.service';
import {DOCUMENT} from '@angular/common';
import {UrlService} from '@vni/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public url: UrlService,
    private auth: AuthService) {
  }

  handleAuthError(e: HttpErrorResponse): Observable<any> {
    if (e.status === 301 && e.error.code === 'MIGRATED_TO_SHOPIFY') {
      if (e.error.shopifyError) {
        return throwError(() => ({
          messageKey: 'Error.CreatingShopifyAccount',
          params: {cause: JSON.stringify(e.error.shopifyError)}
        }));
      } else if (e.error.activationUrl) {
        this.document.location = e.error.activationUrl;
      } else {
        this.document.location = this.url.shopUrl;
      }
    } else {
      return throwError(() => e);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: this.auth.headers()
    });

    return next.handle(req).pipe(catchError(e => this.handleAuthError(e)));
  }
}
