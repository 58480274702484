import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from './auth.service';
import {ErrorHandler} from '../error-handler.service';
import {PasswordResetRequestDialogComponent} from './password-reset-request-dialog.component';
import {UrlService} from '@vni/common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'vni-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @Output() login = new EventEmitter();
  @Output() close = new EventEmitter();

  loginForm: FormGroup;

  constructor(
    private auth: AuthService,
    private errorHandler: ErrorHandler,
    private dialog: MatDialog,
    public url: UrlService) {

    this.loginForm = new FormGroup({
      userName: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
  }

  doLogin() {
    this.auth.login(
      this.loginForm.value.userName,
      this.loginForm.value.password)
      .then(user => this.login.emit(user))
      .catch(error => {
        if (error.status === 401) {
          this.errorHandler.handle('Error.LoggingIn', error);
        } else if (error.messageKey) {
          this.errorHandler.handle(error.messageKey, null, error.params);
        } else {
          this.errorHandler.handle('Error.General', error);
        }
      });
  }

  forgotPassword() {
    this.close.emit();
    this.dialog.open(PasswordResetRequestDialogComponent);
  }
}
